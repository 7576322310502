import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './trade-mobile.css'
import TVChartContainer from "../../Utils/TVChartContainer";
import { SocketContext } from '../../Utils/SocketContext';

const MobileChart = () => {
    let params = useParams()
    let URL = params?.pairs?.split('_');
    const userId = sessionStorage.getItem('userId');
    const [urlPath, setUrlPath] = useState(URL ? URL : []);
    const [CoinPairDetails, setCoinPairDetails] = useState();
    const [SelectedCoin, setSelectedCoin] = useState();
    const [baseCurId, setbaseCurId] = useState();
    const [quoteCurId, setquoteCurId] = useState();
    const [AllData, setAllData] = useState([]);
    const navigate = useNavigate()

    let socketId = sessionStorage.getItem("socketId")

    // ********* Socket Connection and Data fetch ********** //
    const { socket } = useContext(SocketContext);


    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'exchange',
                'socketId': socketId,
                'userId': userId,
            }
            socket.emit('message', payload);
            socket.on('message', (data) => {

                setAllData(data);
            });
        }
    }, [socket]);

    // ********* Auto Select Coin Pair after Socket Connection ********** //
    useEffect(() => {
        if (!SelectedCoin && CoinPairDetails) {
            var Pair;
            var filteredData;
            if (urlPath?.length > 0) {
                filteredData = CoinPairDetails?.filter?.((item) => {
                    return urlPath[0]?.includes(item?.base_currency) && urlPath[1]?.includes(item?.quote_currency)
                })
            }
            if (filteredData?.length > 0) {
                Pair = filteredData[0]
            }
            else {
                Pair = CoinPairDetails[0]
            }
            navigate(`/chart/${Pair?.base_currency}_${Pair?.quote_currency}`);
            setSelectedCoin(Pair);
            setbaseCurId(Pair?.base_currency_id);
            setquoteCurId(Pair?.quote_currency_id);;
            let payload = {
                'message': 'exchange',
                'socketId': socketId,
                'userId': userId,
                'base_currency_id': Pair?.base_currency_id,
                'quote_currency_id': Pair?.quote_currency_id,
            }
            socket.emit('message', payload);
        }
    }, [CoinPairDetails]);

    useEffect(() => {
        let interval;
        if (baseCurId && quoteCurId) {
            interval = setInterval(() => {
                let payload = {
                    'socketId': socketId,
                    'message': 'exchange',
                    'userId': userId,
                    'base_currency_id': baseCurId,
                    'quote_currency_id': quoteCurId,
                }
                socket.emit('message', payload);
            }, 1000)
        }
        return (() => {
            clearInterval(interval)
        })
    }, [baseCurId]);


    useEffect(() => {
        let filteredData = AllData?.pairs
        console.log("🚀 ~ filteredData ~ filteredData:", filteredData)

        const desiredOrder = ['BTC', 'BNB', 'ETH', 'SOL', 'XRP', 'DOGE', 'AVAX', 'SHIB'];
        if (filteredData?.length === 0 || !filteredData) return
        const sortedData = filteredData.sort((a, b) => {
            const aIndex = desiredOrder.indexOf(a.base_currency);
            const bIndex = desiredOrder.indexOf(b.base_currency);
            if (aIndex !== -1 && bIndex !== -1) {
                return aIndex - bIndex;
            }

            if (aIndex !== -1) return -1;
            if (bIndex !== -1) return 1;
            return 0;
        });
        setCoinPairDetails(sortedData)

    }, [AllData]);








    return (
        <>
            <div className="trade-wrapper mobile_trade spot login_bg mb-5 pb-3">
                <div className="spot-container container-fluid p-0">
                    <div className="row g-2" >
                        <div className="col-12" >
                            {/* Trade view */}
                            <div className="trade_card trade_chart mb-1 p-0"  >
                                <div className="cstm_tabs" >
                                    <div  >
                                        {SelectedCoin?.base_currency === undefined ? <div style={{ width: '100%', height: '500px' }}>
                                            <div className="loading-wave " style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                        </div> :
                                            <TVChartContainer symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileChart;
