import { widget } from '../charting_library';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import Datafeed from './datafeed';
import { ProfileContext } from '../../Context';
import logo from './icon.png';
import { deployedUrl } from '../../Api/Api_Config/ApiEndpoints';

export default function TVChartContainer({ symbol }) {
	const { tradeTheme } = useContext(ProfileContext);
	const functCheckRef = useRef(true);
	const [tvWidget, setTvWidget] = useState();

	const getChart = async (symbol) => {
		const activeClass = document.body.className;
		const FinalClass = activeClass.split(' ')[1];
		const widgetOptions = {
			symbol: `${symbol}`,
			load_last_chart: true,
			interval: '1',
			custom_css_url: 'css/style.css',
			fullscreen: false,
			timezone: 'Asia/Kolkata',
			container: 'TVChartContainer',
			datafeed: Datafeed,
			has_intraday: true,
			library_path: '/charting_library/',
			pricescale: 100000000,
			intraday_multipliers: ['1', '60'],
			height: '495px',
			hide_resolution_in_legend: true,
			history: {
				limit: 200, 
			},
			logo: {
				image: logo,
				link: `${deployedUrl}trade/trade`
			},
			time_frames: [],
			time_scale: {
				min_bar_spacing: 1
			},
			// enabled_features: ['move_chart_to_left', 'move_chart_to_right'], 
			disabled_features: ["use_localstorage_for_settings", "adaptive_logo", "border_around_the_chart", 'header_symbol_search', 'header_interval_dialog_button', 'header_compare', 'header_undo_redo', 'header_resolutions'],
			theme: FinalClass === 'theme-light' ? 'light' : 'dark',

			overrides: {
				"symbolInfo.priceFormatter": {
					precision: 4
				},
				"paneProperties.background": FinalClass === 'theme-light' ? '#ffffff' : "#131722",
				"paneProperties.backgroundType": "solid",
				// "scalesProperties.scaleSeriesOnly": true,
			},
			loading_screen: {
				backgroundColor: FinalClass === 'theme-light' ? '#ffffff' : "#131722",
			},
		};
		setTvWidget(new widget(widgetOptions));
	};

	useEffect(() => {
		if (symbol.split('/')[0] !== 'undefined') {
			if (functCheckRef.current) {
				getChart(symbol);
			}
			functCheckRef.current = false;
		}
	}, [symbol]);

	useEffect(() => {
		if (tvWidget) {
			tvWidget.onChartReady(() => {
				const chart = tvWidget.chart();
				if (chart) {
					chart.setSymbol(symbol, () => null);
				}
			});

		}
	}, [symbol]);

	useEffect(() => {
		if (tvWidget) {
			tvWidget.onChartReady(() => {
				const chart = tvWidget.chart();
				if (chart) {
					tvWidget.headerReady().then(function () {
						const intervals = [
							{ value: '1', label: '1 Min' },
							{ value: '5', label: '5 Min' },
							{ value: '15', label: '15 Min' },
							{ value: '30', label: '30 Min' },
							{ value: '60', label: '1 Hour' },
							{ value: 'D', label: '1 Day' },
							{ value: 'W', label: '1 Week' },
							{ value: 'M', label: '1 Month' }
						];
						intervals.forEach(interval => {
							const button = tvWidget.createButton();
							button.classList.add('custom-interval-button')
							button.title = `Switch to ${interval.label}`;
							button.addEventListener('click', function () {
								tvWidget.chart().setResolution(interval.value);
							});
							button.textContent = interval.label;
						});
					});
				}
			});
		}
	}, [tvWidget]);

	useEffect(() => {
		if (tvWidget) {
			const activeClass = document.body.className?.split(' ')[1];
			if (activeClass === 'theme-light') {
				tvWidget.changeTheme("light");
			} else {
				tvWidget.changeTheme("dark");
			}
		}
	}, [tradeTheme]);

	return (
		<>
			<div id='TVChartContainer' />
		</>
	);
}
