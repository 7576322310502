import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {

    return (
        <>
            <section className="page_banner" >
                <div className="container" >
                    <div className="css-wcquo4">
                        <h2 className="css-akh7ai">Trade Crypto Easily - Buy and Sell Using Your Favorite Payment Method</h2>
                    </div>
                </div>
            </section>
            <div className="page_title_bar" >
                <div className="container" >
                    <div className="flex_rows" >
                        <ul className="nav nav-pills  overflowx_scroll funds_tab mb-0  market_tabs pb-0" >
                            <li className="nav-item" id="nav-tab" role="tablist">
                                <Link to='/p2p_trading' className=" active nav-link"  > <i class="ri-p2p-line me-2 ri-xl "></i> P2P Trading</Link>
                            </li>
                        </ul>
                        <nav className="  text-end" >
                            <div className="pt_bar_right" >

                                <Link className="pbr_link  d-none d-md-flex  " to="/my_orders" >
                                    <i class="ri-list-unordered me-2"></i>
                                    Orders
                                </Link>
                                <div className="dropdown pbr_link_dropdown">
                                    <a className=" pbr_link pe-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="ri-more-2-fill me-2"></i>
                                        <span className=" d-none d-md-flex ">More</span>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to="/add_post" className="dropdown-item" > <i class="ri-add-box-line me-2 ri-xl"></i> Add New post</Link> </li>
                                        <li><Link className="dropdown-item" to="/MyPost"> <i class="ri-file-list-3-line me-2  ri-xl"></i> My Posts</Link></li>
                                        <li><Link className="dropdown-item" to="/PaymentMethod"> <i className="ri-bank-card-line me-2 ri-xl"  ></i> Payment Method </Link></li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
