import Pako from "pako";
import { ApiConfig } from "../../Api/Api_Config/ApiEndpoints";
import { makeApiRequest2, parseFullSymbol } from "./helpers";
const { io } = require("socket.io-client");

const channelToSubscription = new Map();

let socket = io(ApiConfig?.webSocketUrl, { transports: ['websocket'], upgrade: false, rejectUnauthorized: false, reconnectionAttempts: 3, debug: true });
let interval;
export async function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	if (interval) {
		clearInterval(interval);
		interval = null;
	}
	if (socket) {
		socket.off('message');
	}
	const channelString = symbolInfo.name;
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
	};
	const parsedSymbol = parseFullSymbol(symbolInfo?.name);
	const ApiData = await makeApiRequest2(parsedSymbol?.fromSymbol, parsedSymbol?.toSymbol);
	let CoinID = ApiData?.currency_ids;
	let historicalDataLength = ApiData?.count;
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) {
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	const userId = sessionStorage.getItem('userId');
	let socketId = sessionStorage.getItem("socketId")
	let payload = {
		'message': 'exchange',
		'base_currency_id': CoinID?.base_currency_id,
		'quote_currency_id': CoinID?.quote_currency_id,
		'cursor': historicalDataLength,
		'userId': userId,
		'socketId': socketId,
	}
	socket.emit('message', payload);
	// interval =setInterval(() => {
	// 	socket.emit('message', payload);
	// }, 5000);
	socket.on('message', (data) => {
		const currPair = data?.pairs?.find(item => item?.base_currency === parsedSymbol.fromSymbol && item?.quote_currency === parsedSymbol.toSymbol);
		if (!currPair) return;
		let changeMiliSecond = currPair?.available === "LOCAL" ? 1000 : 1

		const tickerData = data?.ticker?.[data?.ticker?.length - 1];
		if (!tickerData) return;

		const tradeTime = tickerData?.time;
		const volume = tickerData?.volume;
		const tradePrice = currPair?.buy_price;
		const channelString = symbolInfo?.name;
		const subscriptionItem = channelToSubscription.get(channelString);
		if (subscriptionItem === undefined) {
			return;
		}
		const lastDailyBar = subscriptionItem?.lastDailyBar;
		if (!lastDailyBar) {
			return
		}
		function removeSecondsFromTimestamp(timestamp) {
			const date = new Date(timestamp);
			date.setSeconds(0);
			return date.getTime();
		}
		function getStartOfMinute(timestamp) {
			const date = new Date(timestamp);
			date.setSeconds(0, 0);
			return date.getTime();
		}
		const lastBarTime = getStartOfMinute(lastDailyBar.time);
		const currentTradeMinute = getStartOfMinute(tradeTime * changeMiliSecond);
		let bar;
		if (currentTradeMinute > lastBarTime) {
			bar = {
				time: tradeTime * changeMiliSecond,
				open: lastDailyBar.close,
				high: tradePrice,
				low: tradePrice,
				close: tradePrice,
				// volume: volume,
			};

			subscriptionItem.lastDailyBar = bar
		} else {
			bar = {
				...lastDailyBar,
				high: Math.max(lastDailyBar?.high, tradePrice),
				low: Math.min(lastDailyBar?.low, tradePrice),
				close: tradePrice,
				// volume: volume,
			};

		}
		subscriptionItem.lastDailyBar = bar;
		onRealtimeCallback(bar)

	});
}
export function unsubscribeFromStream(subscriberUID) {
	for (const [channelString, subscriptionItem] of channelToSubscription) {
		const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers?.length === 0) {
				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}
